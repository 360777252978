// import React from 'react';
import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, } from "react-router-dom"
// import { ThemeProvider } from "@mui/material/styles";
import HomePage from "pages/HomePage/HomePage.js";
import Analytics from "pages/dashboard/analytics.js";
import AboutUs from "pages/analyticsTeam/aboutUs.js";
import ReportPage from "pages/Report/ReportPage.js";
import GridReport from "pages/gridReport/agridReport.js";
import Login from "pages/authentication/login.js";
// import LoginSuccessful from "pages/authentication/LandingPage.js";
import { PrivateRoutes } from "utils/PrivateRoutes.js";

import { useOpticStore } from "stores/optics.js";
import "assets/css/analytics.css";

export default function Optics() {
    const isLoggedin = useOpticStore((state) => state.isLoggedin); //check if the user is logged in

return (
    <div className="Optics">
        <Router>
            <Routes>
                <Route element={isLoggedin ? <PrivateRoutes/> : <Navigate to={'/login'}/>}>
                    <Route element={<HomePage/>}   exact path='/'/>
                    <Route element={<Analytics/>}  exact path='/Analytics'/>
                    <Route element={<AboutUs/>}    exact path='/OurTeam'/>
                    <Route element={<ReportPage/>} exact path='/Report'/>
                    <Route element={<GridReport/>} exact path='/GridReport'/>
                </Route>
                <Route element={!isLoggedin ? <Login/>: <Navigate to={'/'}/>} exact path="/login"/> 
            </Routes>
        </Router>
    </div>
  )
}