import React, { } from 'react';
import DashboardLayout from 'components/layouts/dashboard';

export default function AnalyticsPage() {
  return (
    <DashboardLayout>
      <span style={{display:"flex", height:"100vh"}}><h1>The Main Data will go Here</h1></span>
    </DashboardLayout>
  )
}
