import React from "react";
import DashboardLayout from "components/layouts/dashboard";
import { useOpticStore, useSidebarStore } from "stores/optics";
import Grid2 from "@mui/material/Unstable_Grid2"; // Grid version 2
import { Box, Card } from "@mui/material";
import { title } from "assets/jss/Analytics";
import { useNavigate } from "react-router-dom";

export default function HomePage() {
  //Grab Routes from State
  const userRoutes = useOpticStore((state) => state.userRoutes);

  //Sort the Routes
  const sortredRoutes = Object.keys(userRoutes)
    .map((key) => {
      return userRoutes[key];
    })
    .sort((a, b) => (a.links_repo_id > b.links_repo_id ? 1 : -1));
  // Filter to the homepage routes
  const homePageRoutes = Object.keys(sortredRoutes)
    .filter((key) => sortredRoutes[key].home_page_enabled === true)
    .reduce((res, key) => ((res[key] = sortredRoutes[key]), res), {});
  //Generate the report groups
  let reportGroups = {};
  Object.values(homePageRoutes).map((route) => {
    reportGroups[route["group_id"]] = {
      group_id: route.group_id,
      group_type: route.group_type,
      group_icon: route.group_icon,
      group_key: route.group_key,
      group_name: route.group_name,
    };
  });
  const navigate = useNavigate();

  const handleNavButtonClick = (e, route) => {
    e.preventDefault();
    const {
      link_type,
      link_name,
      pathname,
      report_height,
      url,
      key,
      group_name,
    } = route;

    if (link_type === "tableau") {
      //Set the report details
      const ReportDetails = {
        ReportHeight: report_height,
        ReportID: key,
        ReportSubTitle: link_name,
        ReportTableauLink: url,
        ReportTitle: group_name,
      };
      useOpticStore.setState({ tableauReport: ReportDetails }); //store them in the state manager
      navigate(pathname);
      if (window.innerWidth <= 1600) {
        useSidebarStore.setState({sideNavMini: true, isSideNavMini:true})
      }
    }
    if (link_type === "external") {
      window.open(url, "_blank");
    }
    if (link_type === "internal") {
      navigate(pathname);
    }
  };

  // const imgStyle = {boxShadow: '0 2.8px 2.2px rgba(2, 63, 64, 0.10), 0 6.7px 5.3px rgba(2, 63, 64, 0.25), 0 12.5px 9.5px rgba(2, 63, 64, 0.30), 0 10.5px 17.9px rgba(2, 63, 64, 0.35)', borderRadius:'20px', float: 'left', width: '220px', height: '140px', objectFit: 'cover', cursor: 'pointer'};
  const imgStyle = {
    boxShadow:
      "0 1px 1px rgba(2, 63, 64, 0.10), 0 2px 2px rgba(2, 63, 64, 0.25), 0 4px 4px rgba(2, 63, 64, 0.30), 0 5px 5px rgba(2, 63, 64, 0.35)",
    borderRadius: "4px",
    float: "left",
    width: "180px",
    height: "140px",
    objectFit: "cover",
    cursor: "pointer",
  };
  const carTitle = {
    color: "#364156",
    fontWeight: "500",
    textAlign: "center",
    fontSize: "1.155em",
    marginTop: "0px",
    marginBottom: "0px",
    lineHeight: "1.1em",
  };

  return (
    <DashboardLayout>
      <Box sx={{ m: 1, p: 1 }}>
        <Grid2 container={true} spacing={3}>
          {Object.values(reportGroups).map((rg) => {
            const groupRoutes = Object.keys(homePageRoutes)
              .filter((key) => homePageRoutes[key].group_id === rg.group_id)
              .reduce(
                (res, key) => ((res[key] = homePageRoutes[key]), res),
                {}
              ); //filter to the reports in the current group
            let rgReturn = (
              // Create a container
              <div key={rg.group_id} style={{ width: "100%" }}>
                {/* Divide the container into two parts */}
                <Grid2 container={true} spacing={2} flexDirection={"column"}>
                  {/* The first box creates the title for the section */}
                  <Box sx={{ textAlign: "center" }}>
                    <h1
                      style={{ ...title, marginTop: "0px", fontSize: "2rem" }}
                    >
                      {rg.group_name}
                    </h1>
                  </Box>
                  {/* The Second Box Creates the Cards */}
                  <Grid2 container={true} spacing={2}>
                    {Object.values(groupRoutes).map((route) => {
                      try {
                        const thumnail = route["thumnail"];
                        let routeReturn = (
                          <Grid2
                            key={route.key}
                            xs={12}
                            sm={5}
                            md={3}
                            lg={2.4}
                            xl={2.4}
                            padding={0}
                            margin={0}
                            justifyContent="center"
                            display={"inline-flex"}
                          >
                            <Card
                              sx={{
                                width: "100%",
                                height: "200px",
                                margin: "0.65rem",
                                boxShadow: "none",
                                backgroundColor: "#F9FCFF",
                              }}
                            >
                              <Grid2
                                p={0}
                                height={"inherit"}
                                width={"inherit"}
                                display={"flex"}
                                alignItems={"center"}
                                flexDirection={"column"}
                              >
                                <Box>
                                  <img
                                    onClick={(e) => {
                                      handleNavButtonClick(e, route);
                                    }}
                                    src={require("assets/img/" + thumnail)}
                                    alt={route.key}
                                    style={imgStyle}
                                  />
                                </Box>
                                <Box
                                  sx={{
                                    width: "inherit",
                                    height: "100%",
                                    m: 1,
                                    display: "flex",
                                    alignContent: "center",
                                    flexWrap: "wrap",
                                    justifyContent: "center",
                                  }}
                                >
                                  <h1 style={carTitle}>{route.link_name}</h1>
                                </Box>
                              </Grid2>
                            </Card>
                          </Grid2>
                        );
                        return routeReturn;
                      } catch (error) {
                        console.log(error);
                      }
                    })}
                  </Grid2>
                </Grid2>
              </div>
            );
            return rgReturn;
          })}
        </Grid2>
      </Box>
    </DashboardLayout>
  );
}
