import React from "react";
import { title, cardTitle, coloredShadow, grayColor } from "assets/jss/Analytics";
import { makeStyles } from "@material-ui/core/styles"; // @material-ui/core components
// import { GridContainer, GridItem } from "components/grid/grid";
import { Box, Card, } from "@mui/material";
import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2
// import Card from "components/card/card";
import DashboardLayout from 'components/layouts/dashboard';


const pageStyle = () => ({
  title: { ...title, display: 'inline-block', position: 'relative', marginTop: '82px', minHeight: '32px', color: 'inherit', textDecoration: 'none' },
  memberName: { ...title, position: 'relative', fontSize: '1.2rem', marginTop: '5px', marginBottom: '5px' },
  coloredShadow,
  cardTitle,
  mutedText: { '&, & *': { color: grayColor[7], display: 'inline-block' } },

});
const useStyles = makeStyles(pageStyle);

export default function AboutUsPage() {
  const classes = useStyles();
  // const subHeading = `${classes.cardCategory} ${classes.mutedText}`;
  const teamData = [
    { key: 'mKiss', name: 'Michelle Kiss', jobTitle: 'Head of Revenue Management and Analytics', imgURL: "Michelle.jpg" },
    { key: 'nDennett', name: 'Nathaniel Dennett', jobTitle: 'Business Intelligence Manager', imgURL: "Nathaniel.jpg" },
    { key: 'hWirthlin', name: 'Heidi Wirthlin', jobTitle: 'Business Intelligence Analyst', imgURL: "Heidi.jpg" },
    { key: 'cPerry', name: 'Chris Perry', jobTitle: 'Senior Revenue Management Analyst', imgURL: "Chris.jpg" },
  ]

  return (
    <DashboardLayout>
      <Box sx={{ m: 2, p: 2 }}>
        <Box sx={{ ml: 'auto', mr: 'auto', textAlign: "center", m: 1, p: 1 }}>
          <h1 style={{ margin: "1rem 0", ...title, position: 'relative', }}>Our Team</h1>
          <h4>Meet the team that makes it all possible.</h4>
        </Box>
        {/* Main Box for the Members */}
        <Box sx={{ ml: 'auto', mr: 'auto', m: 1, p: 1 }}>
          {/* Grid which will hold each individual from the team */}
          <Grid2 container={true} spacing={4} justifyContent='center'>
            {teamData.map((member) => (
              <Grid2 key={member.key} xs={12} sm={12} md={5} lg={4} xl={4} padding={0} margin={0} justifyContent='center' display={'inline-flex'}>
                {/* Make sure to remove the Shadow */}
                <Card sx={{ width: '330px', height: '200px', margin: '0.65rem', boxShadow: 'none' }}>
                  <Grid2 p={0} height={'inherit'} width={'inherit'} display={'flex'} alignItems={'center'}>
                    <Box mr={1}>
                      <img src={require('assets/img/faces/' + member['imgURL'])} alt={`profile-${member.key}`}
                        style={{
                          boxShadow: '0 5px 15px -8px rgb(0 0 0 / 24%), 0 8px 10px -5px rgb(0 0 0 / 20%)',
                          borderRadius: '20px',
                          float: 'left', width: '140px', height: '140px', objectFit: 'cover',
                        }} />
                    </Box>
                    <Box sx={{ textAlign: 'left' }}>
                      <h2 className={classes.memberName}>{member.name}</h2>
                      <h6 className={classes.mutedText}>{member.jobTitle}</h6>
                    </Box>
                  </Grid2>
                </Card>
              </Grid2>
            ))}
          </Grid2>
        </Box>
      </Box>
    </DashboardLayout>
  );
}
