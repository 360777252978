import React, { useEffect, } from 'react';
import { useLocation } from 'react-router-dom';
import { drawerWidth} from 'assets/jss/Analytics';
import { Box } from '@mui/material';
import { Sidebar, SideBarHeader } from "components/navigation/sideBar/Sidebar";
import PropTypes from "prop-types";
import { useSidebarStore } from "stores/optics";


function DashboardLayout({ children}) {
  const setPageTitle = () => {document.title = 'Industrious Analytics - Home'};
  const scrollToTop = () => {document.documentElement.scrollTop = 0; document.scrollingElement.scrollTop = 0;};
  const { pathname } = useLocation();
  const sidebarOpen = useSidebarStore((state)=>state.sidebarOpen);
  const isSideNavMini = useSidebarStore((state)=>state.isSideNavMini);


  useEffect(() => {
    setPageTitle();
    scrollToTop();
  },[pathname]);

  return (

  <div style={{ position: "relative", top: "0", backgroundColor: "#F9FCFF", "&:after": { display: "table", clear: "both", content: ' ' } }}>
        {/* This creates the sidebar */}
        <Sidebar color={"Industrious"} bgColor={"black"} />
        <SideBarHeader />
        <Box sx={{ p: 0, position: "relative", ml: (sidebarOpen || !isSideNavMini ? `${drawerWidth + (drawerWidth * .1)}px` : `${(drawerWidth * .3)}px`), pt: 10 }}>
          {children}
        </Box>
      </div>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
