import React from 'react';
import PropTypes from 'prop-types'; // nodejs library to set properties for components
import { makeStyles, Grid } from '@material-ui/core'; // @material-ui/core components

const styles = {
  gridContainer: {marginRight: '-15px', marginLeft: '-15px', width: 'auto'},
  gridItem: {position: 'relative', width: '100%', minHeight: '1px', paddingRight: '0px', paddingLeft: '10px'},
};

const useStyles = makeStyles(styles);

function GridContainer(props) {
  const { children, className, ...rest } = props;
  const classes = useStyles();
  return (
    <Grid container {...rest} className={classes.gridContainer + ' ' + className}>
      {children}
    </Grid>
  );
}

function GridItem(props) {
  const { children, className, ...rest } = props;
  const classes = useStyles();
  return (
    <Grid item {...rest} className={classes.gridItem + ' ' + className}>
      {children}
    </Grid>
  );
}

GridItem.defaultProps = {className: ''};
GridItem.propTypes = {children: PropTypes.node, className: PropTypes.string};

GridContainer.defaultProps = {className: ''};
GridContainer.propTypes = {children: PropTypes.node, className: PropTypes.string};

export {
  GridContainer,
  GridItem,
}