import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const apiURl = process.env.REACT_APP_API_URL;
const getHeaders = {
    method: "GET",
    credentials: "include",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
    },
};

const useOpticStore = create(
    persist(
        (set) => ({
            user: {},
            isLoggedin: false,
            toggleLoggedin: () => set((state) => ({ isLoggedin: !state.isLoggedin })),
            loading: false,
            toggleLoading: () => set((state) => ({ loading: !state.loading })),
            activeAuth: false,
            toggleAuth: () => set((state) => ({ activeAuth: !state.activeAuth })),
            userRoles: [],
            userRoutes: {},
            fetchUserRoutes: async () => {
                const response = await fetch(apiURl + "/optics/api/v1/getUserRoutes", getHeaders);
                const json = await response.json();
                set({ userRoutes: json?.routes.dataRecords });
            },
            reportSublinks: {},
            myReportsGroup: {},
            currentRoute: {},
            fetchUser: async () => {
                const response = await fetch(apiURl + "/auth/login/success", getHeaders);
                const json = await response.json();
                set({ user: json?.user, userRoutes: json?.routes.dataRecords, myReportsGroup: json?.myReportsGP.dataRecords, reportSublinks: json?.sublinks.dataRecords });
            },
            tableauToken: '',
            fetchTableauToken: async () => {
                const response = await fetch(apiURl + "/optics/api/v1/getTableauToken", getHeaders);
                const json = await response.json();
                set({ tableauToken: json?.jwtToken });
            },
            updateMyReport: async (id) => {
                const response = await fetch(apiURl + "/optics/api/v1//updateFavorites/", {
                    method: "POST",
                    credentials: "include",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Credentials": true,
                    },
                    body: JSON.stringify({ userFavorites: id }),
                });
                const json = await response.json();
                console.log(json?.results);
            },
            tableauReport: {},
            toggleMyReport: (id) => set((state) => ({ userRoutes: { ...state.userRoutes, [id]: { ...state.userRoutes[id], is_my_report: !state.userRoutes[id].is_my_report, } } })),
        }),
        {
            name: "optics-storage",
            getStorage: () => localStorage,
            serialize: (state) => btoa(JSON.stringify(state)),
            deserialize: (str) => JSON.parse(atob(str))
        }
    )
)
const useSidebarStore = create(
    persist(
        (set) => ({
            selectedReportIndex: [], // initialize as an empty array
            isSelectedReportIndex: false,
            setSelectedReportIndex: (updatedIndex) =>
                set({
                    selectedReportIndex: updatedIndex,
                    isSelectedReportIndex: updatedIndex.length > 0,
                }),
            sidebarOpen: false,
            toggleSidebar: () => set((state) => ({ sidebarOpen: !state.sidebarOpen, sideNavMini: false })),
            sideNavMini: false,
            isSideNavMini: false,
            toggleSideNavMini: () => set((state) => ({ isSideNavMini: !state.isSideNavMini, sideNavMini: !state.sideNavMini })),
            avatarOpen: false,
            toggleAvatar: () => set((state) => ({ avatarOpen: !state.avatarOpen })),
            sideBarColor: "dark",
            floatingSidebar: false,
            toggleFloatingSidebar: () => set((state) => ({ floatingSidebar: !state.floatingSidebar })),
            sideBarDefault: true,
            toggleSideBarDefault: () => set((state) => ({ sideBarDefault: !state.sideBarDefault })),
            openWebSetting: false,
            toggleWebSettings: () => set((state) => ({ openWebSetting: !state.openWebSetting })),
        }),
        {
            name: "sidebar-storage",
            getStorage: () => localStorage,
            serialize: (state) => btoa(JSON.stringify(state)),
            deserialize: (str) => JSON.parse(atob(str)),
        }
    )
);

export {
    useOpticStore,
    useSidebarStore,
};
