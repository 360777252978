import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useOpticStore } from "stores/optics";

const PrivateRoutes = () => {
    let user = useOpticStore((state) => state.user);
    return(
        user ? <Outlet/> : <Navigate to="/login"/>
    )
};

export{
    PrivateRoutes,
}
 
