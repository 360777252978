import React, { useEffect } from "react";
import { title } from "assets/jss/Analytics";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import TableauEmbed from "components/Tableau/TableauEmbed";
import { useOpticStore, useSidebarStore } from "stores/optics";
import DashboardLayout from "components/layouts/dashboard";

export default function ReportPage() {
  const fetchTableauToken = useOpticStore((state) => state.fetchTableauToken);
  const tableauReport = useOpticStore((state) => state.tableauReport);
  const userRoutes = useOpticStore((state) => state.userRoutes); //Grab Routes from State
  const reportSublinks = useOpticStore((state) => state.reportSublinks);
  const [collapse, setCollapse] = useState({});

  const location = useLocation();
  let { pathname, hash } = location;
  let activeReport = pathname + hash;
  const hasReport = Object.keys(tableauReport).length > 0 ? true : false; //Check if there is an object already loaded.
  const navigate = useNavigate();
  const tableauReportRoute = Object.keys(userRoutes)
    .filter((key) => userRoutes[key].pathname === activeReport)
    .reduce((res, key) => ((res[key] = userRoutes[key]), res), {});

  let route = tableauReportRoute[Object.keys(tableauReportRoute)[0]];
  const { link_type, link_name, report_height, url, key, group_name } = route;
  //Filter to User Routes to the selected report
  //Built in redirect to home if the users are trying to access some report they shouldn't
  if (Object.keys(tableauReportRoute).length === 0) {
    console.log("You are not Authorized to use report,", activeReport);
    navigate("/");
  }
  //No active report loaded
  if (!hasReport || tableauReport?.ReportID !== key) {
    if (link_type === "tableau") {
      //Set the report details
      let ReportDetails = {
        ReportHeight: report_height,
        ReportID: key,
        ReportSubTitle: link_name,
        ReportTableauLink: url,
        ReportTitle: group_name,
      };
      useOpticStore.setState({ tableauReport: ReportDetails }); //store them in the state manager
      fetchTableauToken();
      navigate(activeReport);
    }
  }

  //button
  const sublinks = reportSublinks.filter(
    (sublink) => sublink.link_id === route.links_repo_id
  );
  const handleClick = (sublink) => {
    navigate(sublink.pathname);
    if (window.innerWidth <= 1600) {
      useSidebarStore.setState({sideNavMini: true, isSideNavMini:true})
      setCollapse({ [sublink["group_name"]]: !collapse[sublink["group_name"]] });
    }
  };

  useEffect(() => {
    fetchTableauToken();
  }, []);

  const [selectedButtonIndices, setSelectedButtonIndices] = useState({});
  const [hoverButtonIndicesPerReport, setHoverButtonIndicesPerReport] =
    useState({});

    sublinks.sort((a, b) => a.sort_id - b.sort_id);

  let buttons = sublinks.map((sublink, index) => {
    const reportId = sublink.pathname.split("/").slice(0, 3).join("_");
    const selectedButtonIndex = selectedButtonIndices[reportId] ?? -1;
    const hoverButtonIndex = hoverButtonIndicesPerReport[reportId] ?? -1;
    return (
      <button
        key={undefined}
        style={{
          fontSize: "15px",
          fontWeight: "bold",
          border: "none",
          borderRadius: "8px",
          padding: "12px 24px",
          width: "160px",
          height: "75px",
          margin: "10px 5px",
          backgroundColor:
            selectedButtonIndex === index
              ? "#122932"
              : hoverButtonIndex === index
              ? "#122932"
              : "#8DB0C9",
          color:
            selectedButtonIndex === index
              ? "white"
              : hoverButtonIndex === index
              ? "white"
              : "black",
          boxShadow:
            selectedButtonIndex === index
              ? "0px 0px 10px rgba(0, 0, 0, 0.5)"
              : hoverButtonIndex === index
              ? "0px 0px 10px rgba(0, 0, 0, 0.5)"
              : "0 3px 3px rgba(0, 0, 0, 0.2)",
          cursor: "pointer",
          outline: "none",
          transition: "all 0.2s ease-in-out",
        }}
        onClick={() => {
          setSelectedButtonIndices(() => ({
            //...prev,
            [reportId]: index,
          }));
          handleClick(sublink);
          setHoverButtonIndicesPerReport(() => ({
            //...prev,
            [reportId]: index,
          }));
        }}
        onMouseEnter={() => {
          if (selectedButtonIndex !== index) {
            setHoverButtonIndicesPerReport(() => ({
              //...prev,
              [reportId]: index,
            }));
          }
        }}
        onMouseLeave={() => {
          if (selectedButtonIndex !== index) {
            setHoverButtonIndicesPerReport(() => ({
              //...prev,
              [reportId]: -1,
            }));
          }
        }}
      >
        {sublink.sub_name}
      </button>
    );
    
  });
  return (
    <DashboardLayout>
      <div id="ReportHeading" style={{ margin: "0 auto", textAlign: "center" }}>
        <h1 style={{ ...title, marginTop: "0px", marginBottom: "0px" }}>
          {tableauReport.ReportTitle}
        </h1>
        <h1 style={{ fontSize: "1.5rem" }}>{tableauReport.ReportSubTitle}</h1>
      </div>
      <div style={{ position: "sticky", top: 0, zIndex: 1}}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginTop: "30px",
          }}
        >
          {sublinks.length > 0 && buttons}
        </div>
      </div>
      <div
        id="tableauReport"
        style={{ display: "flex", justifyContent: "center", padding: ".8rem" }}
      >
        <TableauEmbed />
      </div>
    </DashboardLayout>
  );
}
