import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import { makeStyles, AppBar, Toolbar, Hidden, Button } from '@material-ui/core';// @material-ui/core components
import { LoadingButton } from '@mui/lab';
import { container, defaultFont, title, blackColor, whiteColor, hexToRgb, infoColor } from "assets/jss/Analytics";
import { GridContainer, GridItem } from "components/grid/grid";
import analytics from "assets/img/sAnalytics.jpg";
import Logo from '../../assets/img/logo.png'
import { useOpticStore } from "stores/optics";

const mainStyles = () => ({
  appBar: { display: 'flex', backgroundColor: "#122932", width: '100%', transition: 'all 150ms ease 0s', alignItems: 'center', position: 'fixed' },
  appBarContainer: { ...container, justifyContent: 'space-between' },
  appBarTitle: { letterSpacing: 'unset', '&,& a': { ...defaultFont, minWidth: 'unset', lineHeight: '30px', fontSize: '2rem', borderRadius: '3px', textTransform: 'none', whiteSpace: 'nowrap', color: 'inherit', '&:hover,&:focus': { color: 'inherit', background: 'transparent' } } },
  subContainer: { ...container, zIndex: "2", position: "relative", "& h1, & h4, & h6": { color: whiteColor } },
  title,
  hidden: { width: '100%' },
  mainContainer: {
    position: "relative", height: "100vh", backgroundSize: "cover", display: "flex", alignItems: "center", "&:before": { background: "rgba(" + hexToRgb(blackColor) + ", 0.5)" },
    "&:after,&:before": { position: "absolute", zIndex: "1", width: "100%", height: "100%", display: "block", left: "0", top: "0", content: "''" }
  },
  info: {
    backgroundColor: infoColor[0],
    boxShadow: '0 2px 2px 0 rgba(' + hexToRgb(infoColor[0]) + ', 0.14), 0 3px 1px -2px rgba(' + hexToRgb(infoColor[0]) + ', 0.2), 0 1px 5px 0 rgba(' + hexToRgb(infoColor[0]) + ', 0.12)',
    '&:hover,&:focus': { backgroundColor: infoColor[0], boxShadow: '0 14px 26px -12px rgba(' + hexToRgb(infoColor[0]) + ', 0.42), 0 4px 23px 0px rgba(' + hexToRgb(blackColor) + ', 0.12), 0 8px 10px -5px rgba(' + hexToRgb(infoColor[0]) + ', 0.2)' }
  }
});
const useStyles = makeStyles(mainStyles);

export default function Login() {
  const classes = useStyles();
  const isLoggedin = useOpticStore((state) => state.isLoggedin);
  const activeAuth = useOpticStore((state) => state.activeAuth);
  const toggleAuth = useOpticStore((state) => state.toggleAuth);
  const toggleLoading = useOpticStore((state) => state.toggleLoading);
  const toggleLoggedin = useOpticStore((state) => state.toggleLoggedin);
  const fetchUser = useOpticStore((state) => state.fetchUser);
  const userProfile = useOpticStore((state) => state.user);
  const userRoutes = useOpticStore((state) => state.userRoutes);
  const apiURl = process.env.REACT_APP_API_URL;

  const userStatus = () => {
    if (isLoggedin === false) {
      // console.log('User has not logged in.');
      if (activeAuth === true) {
        if (Object.keys(userProfile).length === 0) {
          console.log('Fetching User Profile');
          fetchUser();
        }
        if (Object.keys(userProfile).length > 0 && userRoutes.length > 0) {
          console.log('User has successfully logged in.');
          toggleLoggedin();
        }
      }
    }
  };

  useEffect(() => {
    userStatus();
  }, [activeAuth, userProfile, userRoutes]);

  const google = () => {
    toggleAuth();
    toggleLoading();
    window.open(apiURl + "/auth/google", "_self")
  };
  const buttonStyle = {
    backgroundColor: infoColor[0],
    boxShadow: '0 2px 2px 0 rgba(' + hexToRgb(infoColor[0]) + ', 0.14), 0 3px 1px -2px rgba(' + hexToRgb(infoColor[0]) + ', 0.2), 0 1px 5px 0 rgba(' + hexToRgb(infoColor[0]) + ', 0.12)',
    '&:hover,&:focus': { backgroundColor: infoColor[0], boxShadow: '0 14px 26px -12px rgba(' + hexToRgb(infoColor[0]) + ', 0.42), 0 4px 23px 0px rgba(' + hexToRgb(blackColor) + ', 0.12), 0 8px 10px -5px rgba(' + hexToRgb(infoColor[0]) + ', 0.2)' },
    '&.Mui-disabled': { backgroundColor: infoColor[0], },
  }
  return (
    <div className={classes.mainContainer} style={{ backgroundImage: `url("${analytics}")` }}>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.appBarContainer}>
          <Button className={classes.appBarTitle} color='primary'><Link to='/'><img src={Logo} alt="icon" height='30' style={{ marginRight: '8px' }} />Analytics</Link></Button>
          <Hidden implementation='js' className={classes.hidden}>
            <div style={{ display: 'flex', marginTop: '0px' }}>
              <LoadingButton sx={{ ...buttonStyle }} onClick={google} loading={activeAuth} variant="contained"><span>Sign In</span></LoadingButton>
              {/* <Button onClick={google} variant="contained" className={classes.info}>Sign In</Button> */}
            </div>
          </Hidden>
        </Toolbar>
      </AppBar>
      <div className={classes.subContainer}>
        <GridContainer>
          <GridItem xs style={{ textAlign: "right" }}><h1 style={{ fontSize: "2rem" }}>{activeAuth ? 'Loading your content. This may take a moment.' : ''}</h1></GridItem>
          {/* We should add some sort of loading Message */}
        </GridContainer>
      </div>
    </div>
  );
}
