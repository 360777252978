import React, { useState, useEffect, useRef } from 'react';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

const AGGrid = () => {
    const [rowData, setRowData] = useState([]);
    const gridRef = useRef(null);
    const [gridApi, setGridApi] = useState(null);
  
    const onGridReady = (params) => {
          setGridApi(params.api);
    };

    useEffect(() => {
         fetch('https://www.ag-grid.com/example-assets/row-data.json')
         .then(result => result.json())
          .then(rowData => setRowData(rowData))
    }, []);

    const onButtonClick = () => {
            const selectedNodes = gridRef.current.api.getSelectedNodes()
            const selectedData = selectedNodes.map( node => node.data )
            const selectedDataStringPresentation = selectedData.map( node => `${node.make} ${node.model}`).join(', ')
            alert(`Selected nodes: ${selectedDataStringPresentation}`)
    }

    const onBtnExport = () => {
        gridApi.exportDataAsCsv();
      };
    const enableCharts = true;
    const enableRangeSelection = true;

    return (
        <div className="ag-theme-alpine" style={{height: 1000, width: 1000}}>
            <button onClick={onButtonClick}>Get selected rows</button>
            <button onClick={() => onBtnExport()}>Download CSV export file</button>
            <AgGridReact ref={gridRef} rowData={rowData} rowSelection="multiple" onGridReady={onGridReady} enableCharts={enableCharts} enableRangeSelection={enableRangeSelection}>
                <AgGridColumn field="make" sortable={true} filter={true} checkboxSelection={true} rowGroup={ true }></AgGridColumn>
                <AgGridColumn field="model" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="price" sortable={ true } filter={ true }></AgGridColumn>
            </AgGridReact>
        </div>
    );
}

export { 
    AGGrid,
};