import React, { } from 'react';
import { useOpticStore, } from "stores/optics";

export default function TableauEmbed() {
  const tableauReport = useOpticStore((state)=> state.tableauReport);
  const tableauToken = useOpticStore((state)=> state.tableauToken);
  const reportFilter = tableauReport.ReportFilter;
  const url = "https://us-east-1.online.tableau.com/t/industriousoffice/views/" + tableauReport.ReportTableauLink; //+ '?:embed=yes';
  let viz_filter_field_encoded = ""
  let viz_filter_value_encoded = ""

  if(reportFilter){
    viz_filter_field_encoded = reportFilter.slice(reportFilter.indexOf('?')+1,reportFilter.indexOf('~'));
    viz_filter_value_encoded = reportFilter.slice(reportFilter.indexOf('~')+1);
  }

  const viz_filter_field = decodeURI(viz_filter_field_encoded)
  const viz_filter_value = decodeURI(viz_filter_value_encoded)

  return (
  <>
    <tableau-viz id="tableauViz" src={url} token={tableauToken} device="default" toolbar="top" height={tableauReport.ReportHeight}
      style={{display:'flex', flexDirection: 'row', flexWrap: 'wrap', alignContent: 'center', justifyContent: 'center', alignitems: 'center', width: '95vw', paddingRight:'10px'}}
      hide-tabs> 
      <viz-filter field={viz_filter_field} value={viz_filter_value}></viz-filter>  
    </tableau-viz>
  </>
  )
}