import React from 'react';
import { AGGrid } from 'components/AGGrid/Aggrid';
import DashboardLayout from 'components/layouts/dashboard';

export default function GridReportPage() {
  return (
    <DashboardLayout>
      <div style={{display:'flex', justifyContent:'center', margin:'1rem'}}>
        <div id="ag-grid-report" style={{height:'100vh'}}><AGGrid/></div>
      </div>
    </DashboardLayout>
  )
}